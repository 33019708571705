// 关于我们
<template>
  <div class="about-us" :id="id">
    <h2>关于我们</h2>
    <p>{{ systemInfo.unit }}</p>
    <div class="organization-info">
      <h3>部门简介</h3>
      <p>{{ systemInfo.description }}</p>
    </div>
    <div class="contact-info">
      <h3>联系方式</h3>
      <ul>
        <li>地址：{{ systemInfo.address }}</li>
        <li>电话：{{ systemInfo.phone }}</li>
        <li>邮箱：{{ systemInfo.email }}</li>
      </ul>
    </div>

    <div class="feedback-form">
      <h3>投诉建议</h3>
      <p>如果您有任何意见或建议，请通过以上方式联系我们，也可以在留言区进行反馈。</p>
      <form @submit.prevent="handleSubmit">
        <label for="feedback">反馈内容：</label>
        <textarea
            id="feedback"
            v-model="feedback"
            required
            placeholder="您的意见或建议"
        >
        </textarea>

        <div>
          <label for="contactInformation">您的联系方式：</label>
          <input
              type="text"
              id="contactInformation"
              v-model="contactInformation"
              required
              placeholder="请输入电话、微信、邮箱等"
              autocomplete="off"
          >
          <button type="reset" class="right-align" @click="resetForm">重置</button>
          <button type="submit">提交</button>
        </div>
      </form>
    </div>

    <div class="about-us-image">
      <img src="/先锋圳上公众号.png" alt="先锋圳上" />
    </div>
  </div>

  <!-- 弹窗 -->
  <Teleport to="body">
    <transition name="dialog-fade">
      <div v-if="submitStatus" class="dialog" @click.self="closeDialog">
        <div class="dialog-content">
          <p v-if="submitStatus === 'success'" class="success-message">
            感谢您提交意见和建议，版本测试中，无法回复您的意见和建议，在正式版本发行后，我们将尽快处理并回复您。
          </p>
          <p v-if="submitStatus === 'error'" class="error-message">
            提交失败，请稍后再试。
          </p>
          <div class="dialog-buttons">
            <button @click="closeDialog">关闭</button>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup>
import axios from "axios";
import {ref, onMounted} from "vue";

// 定义响应式数据
const props = defineProps({  id: String});
const systemInfo = ref({});
const feedback = ref("");
const contactInformation = ref("");
const submitStatus = ref(null); // 用于跟踪提交状态

// 重置表单
const resetForm = (event) => {
  event.preventDefault(); // 阻止默认的表单重置行为
  feedback.value = ""; // 清空反馈内容
  contactInformation.value = ""; // 清空联系信息
  submitStatus.value = null; // 清除提交状态
};

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;
onMounted(async () => {
  try {
    const response = await axios.get(`${baseUrl}/webapi/systemInfo/list`);
    systemInfo.value = response.data.data[0];
  } catch (error) {
    console.error("Error fetching data:", error);
  }
});

// 处理提交逻辑
const handleSubmit = async () => {
  try {
    // 在这里添加实际的提交逻辑，例如发送到服务器
    const response = await axios.post(`${baseUrl}/webapi/webfeedbackContent/add`, {
      username: "游客",
      feedbackContent: feedback.value,
      contactInformation: contactInformation.value,
    });

    if (response.status === 200) {
      submitStatus.value = 'success';
      feedback.value = ""; // 清空反馈内容
      contactInformation.value = ""; // 清空联系信息
    }
  } catch (error) {
    console.error("Error submitting feedback-manage:", error);
    submitStatus.value = 'error';
  }
};

// 关闭弹窗
const closeDialog = () => {
  submitStatus.value = null;
};
</script>

<style lang="scss" scoped>
.about-us {
  font-family: Arial, sans-serif;
  width: 1200px;
  margin: 0 auto;
  position: relative;

  .about-us-image {
    position: absolute;
    top: 40px; /* 距离顶部的距离 */
    right: 0; /* 距离右侧的距离 */
    max-width: 360px; /* 设置图片的最大宽度 */

    img {
      width: 100%; /* 让图片宽度自适应 */
      height: auto; /* 保持图片的纵横比 */
    }
  }

  h2 {
    margin-top: 15px;
  }

  h3 {
    margin-top: 10px;
  }

  p, li {
    margin-bottom: 5px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  textarea {
    resize: vertical;
    min-height: 219px;
    padding: 5px;
  }

  .feedback-form div {
    padding: 0 34px 0 0;
    display: flex;
    align-items: center;

    input {
      width: 350px;
      height: 28px;
      line-height: 28px;
      display: block;
      padding: 5px 0 0 5px;
    }

    .right-align {
      margin-left: auto;
    }

    button {
      padding: 3px 8px;
      margin: 10px 20px 10px 0;
      cursor: pointer;
    }
  }
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  .dialog-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;

    .success-message, .error-message {
      width: 360px;
      height: 100%;
      margin-bottom: 10px;
    }

    .dialog-buttons {
      display: flex;
      justify-content: flex-end;
      margin: 10px 8px 0 0;
    }

    button {
      padding: 3px 8px;
      cursor: pointer;
    }
  }
}

</style>
