<!--新闻组件-->
<template>
  <div
    :style="{'background-image': `url(/news/新闻中心背景.jpg)`}"
    class="news-header"
  >
    <SearchComponent/>
  </div>

  <!-- 根据点击量，显示前四条新闻 -->
  <div class="news-container">
    <el-row :gutter="20">
      <el-col
        v-for="item in topNewsList"
        :key="item._id"
        :span="6"
      >
        <el-card
          :body-style="{ padding: 0 }"
          shadow="hover"
          @click="handleChangePage(item._id)"
          class="pointer"
        >
          <div
            :style="{ backgroundImage: `url(${item.cover})` }"
            class="image"
          ></div>
          <div style="padding: 14px">
            <span class="news-title">{{ item.title }}</span>
            <div class="bottom">
              <time class="time">{{ whichTime(item.publicationTime) }}</time>
              <span class="clicks">阅读 {{ item.clicks }}</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-tabs
      v-model="whichTab"
    >
      <el-tab-pane
        v-for="item in tabList"
        :key="item.name"
        :label="item.label"
        :name="item.name"
      >
        <el-row :gutter="20">
          <el-col :span="18">
            <div
              v-for="data in tabnews[item.name]"
              :key="data._id"
              class="tab-item"
              style="padding: 10px 10px 10px 0;"
              @click="handleChangePage(data._id)"
            >
              <el-card
                :body-style="{ padding: 0 }"
                shadow="hover"
                class="pointer"
              >
                <div
                  :style="{ 'backgroundImage': `url(${data.cover})` }"
                  class="tab-image"
                ></div>
                <div style="padding: 14px; float: left;">
                  <span class="news-title">{{ data.title }}</span>
                  <div class="bottom">
                    <time class="tab-time">{{ whichTime(data.publicationTime) }}</time>
                  </div>
                  <span class="news-author">作者：{{ data.author }}</span>
                  <span class="clicks">阅读 {{ data.clicks }}</span>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="6">
            <el-timeline>
              <el-timeline-item
                v-for="(data, index) in tabnews[item.name]"
                :key="index"
                :timestamp="whichTime(data.publicationTime)"
              >
                <span class="pointer" @click="handleChangePage(data._id)" style="font-size: 15px">{{ data.title }}</span>
              </el-timeline-item>
            </el-timeline>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import moment from 'moment';
import axios from 'axios';
import SearchComponent from "@/views/News/SearchComponent.vue";

moment.locale('zh-cn');

const newlist = ref([]);
const whichTab = ref(1);

const baseUrl = process.env.VUE_APP_API_BASE_URL;

const topNewsList = computed(() => {
  const sortedList = [...newlist.value].sort((a, b) => b.clicks - a.clicks);
  return sortedList.slice(0, 4);
});

const whichTime = time => moment(time).format('lll');

const tabList = [
  { label: '最新动态', name: 1 },
  { label: '先锋圳上', name: 4 },
  { label: '通知公告', name: 3 },
  { label: '工作动态', name: 5 },
  { label: '纪检监察', name: 2 },
  { label: '政府工作报告', name: 6 },
];

const tabnews = ref({});

const router = useRouter();

const handleChangePage = (id) => {
  const currentTitle = tabList.find(tab => tab.name === whichTab.value)?.label || '返回';
  router.push({
    path: `/new/${id}`,
    query: { parentTitle: currentTitle }
  });
};

const fetchNewsData = async (category) => {
  try {
    const res = await axios.get(`${baseUrl}/webapi/news/category/${category}`);
    tabnews.value[category] = res.data.data;
  } catch (error) {
    console.error(`获取新闻列表失败 (类别 ${category}):`, error);
  }
};

// 获取点击率前几条新闻列表
const top = 4;
const fetchTopNewsData = async () => {
  try {
    const res = await axios.get(`${baseUrl}/webapi/news/clicks/${top}`);
    newlist.value = res.data.data; // 将获取的数据赋值给 newlist
  } catch (error) {
    console.error(`获取置顶新闻列表失败:`, error);
  }
};

watch(whichTab, async (newCategory) => {
  if (!tabnews.value[newCategory]) {
    await fetchNewsData(newCategory);
  }
});

onMounted(async () => {
  await fetchTopNewsData();
  await fetchNewsData(1);
});
</script>

<style lang="scss" scoped>
.news-header {
  position: relative;
  width: 100%;
  height: 338px;
  background-size: cover;
}

.news-container {
  width: 100%;
  margin-top: 20px;

  .image {
    width: 100%;
    height: 188px;
    background-size: cover;
  }

  .news-title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;

    .time {
      font-size: 13px;
      color: gray;
    }

    .clicks {
      font-size: 13px;
      color: gray;
    }
  }
}

.el-tabs {
  margin-top: 20px;

  .tab-image {
    width: 150px;
    height: 100px;
    background-size: cover;
    float: left;
  }

  .tab-time {
    font-size: 13px;
    color: gray;
  }

  .news-author, .clicks {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
  }
}
</style>
