<!--AI网址链接-->
<template>
  <div class="homeAILink container-style">
    <div
      v-for="(link, index) in links"
      :key="index"
      class="link"
      @click="openLink(link)"
      :title="link.tooltip"
    >
      {{ link.label }}
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

// 定义AI网址链接数组
const links = ref([
  {
    "label": "DeepSeek(深度求索)",
    "website": "https://chat.deepseek.com/",
    "tooltip": "智能对话平台，在图像生成与自然语言处理领域表现出色"
  },
  {
    "label": "文心一言(百度)",
    "website": "https://yiyan.baidu.com/",
    "tooltip": "自然语言处理、文本生成、多轮对话、图像创作等"
  },
  {
    "label": "通义千问(阿里云)",
    "website": "https://tongyi.aliyun.com/qianwen/",
    "tooltip": "信息检索与多语言翻译能力强，适用于文本处理等场景"
  },
  {
    "label": "腾讯混元(腾讯公司)",
    "website": "https://hunyuan.tencent.com/",
    "tooltip": "高质量内容创作、数理逻辑、代码生成和多轮对话上性能表现卓越"
  },
  {
    "label": "KIMI(月之暗面)",
    "website": "https://kimi.moonshot.cn/",
    "tooltip": "长文本处理能力突出，适用于文案策划等场景"
  },
  {
    "label": "亿图脑图",
    "website": "https://mm.edrawsoft.cn/app/create",
    "tooltip": "直观的思维导图工具，助力高效 brainstorming 和项目规划"
  },
  {
    "label": "豆包(字节跳动)",
    "website": "https://www.doubao.com/chat/",
    "tooltip": "个性化推荐，多模态处理，智能对话，广泛应用于内容创作和生活助手"
  },
  {
    "label": "讯飞星火(科大讯飞)",
    "website": "https://xinghuo.xfyun.cn/desk",
    "tooltip": "卓越的语音识别与自然语言处理，广泛应用于教育、医疗等领域"
  }
])

// 打开链接的方法
const openLink = (link) => {
  if (link.website !== '') window.open(link.website, '_blank');
}
</script>

<style scoped lang="scss">
.homeAILink {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  gap: 10px; /* 添加间距 */
  padding: 10px 0 10px 10px;
}

.link {
  width: calc(25% - 10px); /* 每行四个标签，减去间距 */
  display: flex;
  justify-content: center;
  align-items: center; /* 调整对齐方式 */
  background-color: #d5e3f1;
  padding: 10px;
  cursor: pointer; /* 添加鼠标指针样式 */
  border-radius: 5px; /* 添加圆角 */
  transition: background-color 0.3s; /* 添加过渡效果 */
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden; /* 隐藏溢出的文本 */
  text-overflow: ellipsis; /* 显示省略号 */
  border: 1px solid #b8d1e9; /* 添加边框 */
}

.link:hover {
  background-color: #b8d1e9; /* 鼠标悬停时改变背景颜色 */
  border: 1px solid #d3e1ef; /* 添加边框 */
}
</style>
