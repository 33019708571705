<template>
  <div class="common-layout container-style">
    <el-container>
      <el-main>
        <div class="image-container">
          <img
            v-if="currentImageIndex !== null"
            :src="images[currentImageIndex].hoverSrc"
            :alt="images[currentImageIndex].alt"
            class="image-preview"
          />
        </div>
      </el-main>

      <el-aside width="104px">
        <div class="image-labels">
          <div
              v-for="(image, index) in images"
              :key="index"
              @mouseover="currentImageIndex = index"
              class="label"
              :class="{ active: currentImageIndex === index }"
          >
            {{ image.label }}
          </div>
        </div>
      </el-aside>
    </el-container>
  </div>

  <FrequentlyUsedLinks/>

  <div style="padding: 20px 0">
    <a href="https://zwfw-new.hunan.gov.cn/hnzwfw/1/14/170/index.htm" target="_blank" class="additional-link">
      湖南政务服务网（新化站点）
    </a>
  </div>
</template>

<script setup>
import FrequentlyUsedLinks from "@/views/Service/FrequentlyUsedLinks.vue";
import { ref, onMounted } from 'vue';

const images = ref([
  { src: '机关办公楼平面图1.jpg', hoverSrc: '机关办公楼平面图1.jpg', alt: '机关办公楼平面图1', label: '机关一楼' },
  { src: '机关办公楼平面图2.jpg', hoverSrc: '机关办公楼平面图2.jpg', alt: '机关办公楼平面图2', label: '机关二楼' },
  { src: '机关办公楼平面图3.jpg', hoverSrc: '机关办公楼平面图3.jpg', alt: '机关办公楼平面图3', label: '机关三楼' },
  { src: '机关办公楼平面图4.jpg', hoverSrc: '机关办公楼平面图4.jpg', alt: '机关办公楼平面图4', label: '财政所' },
  { src: '机关办公楼平面图5.jpg', hoverSrc: '机关办公楼平面图5.jpg', alt: '机关办公楼平面图5', label: '政务中心' }
]);

const currentImageIndex = ref(null);

onMounted(() => {
  currentImageIndex.value = 0; // 设置默认显示一楼
});
</script>

<style lang="scss" scoped>
.common-layout{
  margin-bottom: 20px;
}

.additional-link {
  display: block;
  margin-top: 10px;
  text-decoration: none;
  color: #414141;
  font-weight: bold;
}

.additional-link:hover {
  text-decoration: underline;
}

.image-labels {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* 添加此行以实现垂直居中 */
  height: 100%; /* 添加此行以确保父容器高度被正确应用 */
}

.label {
  width: 86px; /* 设置标签宽度为86px */
  margin: 5px 0;
  padding: 5px 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  text-align: center; /* 添加此行以使文字居中 */
}

.label.active {
  background-color: #007bff;
  color: white;
}

.image-container {
  display: flex;
  justify-content: center;
}

.image-preview {
  width: 100%;
  height: auto;
}
</style>